<template>
  <div v-if="showSwatches" class="swatch-wrapper" :class="size">
    <span v-if="size === 'small'" class="swatch-label">{{ label }}</span>
    <h2 v-else class="swatch-label">{{ label }}</h2>
    <div class="swatches">
      <template v-for="(swatch, index) in swatches">
        <mp-link
          :key="'swatch-' + index"
          class="swatch"
          :class="[{ selected: swatch.product === productId }, swatch.type]"
          :to="$u(swatch.url)"
          event=""
          @click.native="
            $emit('setSwatch', {
              productId: swatch.product,
              swatchUrl: swatch.url,
            })
          "
        >
          <span
            v-if="swatch.type === 'volume' && swatch.volume"
          >
            {{ swatch.volume }}
          </span>

          <span
            v-if="swatch.type === 'color'"
            class="link"
            :style="{ backgroundColor: swatch.swatch.cssColorValue }"
          />
        </mp-link>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductDescription',

  props: {
    productId: {
      type: String,
      required: true,
    },
    swatches: {
      type: Array,
      required: false,
      default: () => [],
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    label() {
      if (this.swatches && this.swatches.length) {
        return this.$t(`PDP_Title_${this.swatches[0].type}`)
      }
      return ''
    },
    showSwatches() {
      if (this.swatches.length === 1) {
        if (this.swatches[0].type === 'volume' && !this.swatches[0].volume)
          return false
        if (this.swatches[0].type === 'color' && !this.swatches[0].swatch)
          return false
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.swatch-wrapper {
  display: none;
  .swatch-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--side-padding);
    @include p--medium;
    font-weight: 600;
    margin: 0;
  }
  .swatches {
    padding: 0 calc(var(--side-padding) - 0.4rem);
    margin: 0.6rem 0 1.6rem 0;
    display: flex;
    flex-wrap: wrap;
    .swatch {
      &.volume {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1.2rem;
        border: 1px solid $blue-dark;
        border-radius: 2rem;
        margin: 0.4rem;
        height: 2.8rem;
        position: relative;
        cursor: pointer;
        @include p--medium;
        &.selected {
          background: $blue-dark;
          color: $white;
        }
      }
      &.color {
        margin: 0.4rem;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 1.6rem;
        position: relative;
        .link {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 1.6rem;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: -3px;
          left: -3px;
          width: calc(100% + 6px);
          height: calc(100% + 6px);
          border-radius: 100%;
          border: 1px solid $blue;
          opacity: 0;
          pointer-events: none;
        }
        &.selected {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
  &.small {
    .swatch-label {
      display: none;
    }
    .swatches {
      padding: 0;
      margin: 0.8rem -0.2rem 0 -0.2rem;
      display: flex;
      flex-wrap: wrap;
      .swatch {
        &.volume {
          @include p--small;
          font-size: 0.9rem;
          height: 1.7rem;
          margin: 0.2rem;
          padding: 0 0.8rem;
          border: 1px solid $blue-dark;
          &.selected {
            background: $blue-dark;
          }
        }
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .swatch-wrapper {
    display: block;
    .swatch-label {
      @include p--large;
    }

    &.small {
      .swatches {
        .swatch {
          &.volume {
            @include p--small;

            height: 2.4rem;
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
