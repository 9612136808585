<template>
  <transition
    enter-class="slide-in-top-enter"
    enter-to-class="slide-in-top-to"
    enter-active-class="slide-in-top-enter-active"
  >
    <div v-show="searchOpen" class="search-container">
      <div class="search-box">
        <SearchIcon class="icon" @click="searchSubmit" />
        <form action class="search-form" @submit="searchSubmit">
          <input
            :id="inputid"
            ref="searchInput"
            v-model="inputValue"
            type="search"
            class="search-input"
            :placeholder="$t('Global_Search_Placeholder')"
          />
        </form>
      </div>
      <CloseIcon
        v-if="!hideClose"
        class="icon close-icon"
        @click="hideSearch"
      />
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import SearchIcon from '~/static/images/search.svg'
import CloseIcon from '~/static/images/cross.svg'

export default {
  name: 'SearchInput',
  components: {
    SearchIcon,
    CloseIcon,
  },
  props: {
    inputid: {
      type: String,
      default: '',
    },
    hideClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputValue: '',
    }
  },
  computed: {
    ...mapState({
      searchOpen: (state) => state.ui.search.searchOpen,
    }),
  },
  watch: {
    searchOpen(newValue) {
      if (newValue && this.$refs.searchInput) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus()
        })
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.searchInput && !this.hideClose && this.searchOpen) {
        this.$refs.searchInput.focus()
      }
    })
  },
  methods: {
    hideSearch() {
      this.$store.dispatch('ui/search/hideSearch')
    },
    getAction() {
      return this.$u('search')
    },
    searchSubmit(e) {
      e.preventDefault()
      this.search()
    },
    search() {
      const newPath = `${this.getAction()}?q=${this.inputValue}`

      this.$router.push(newPath)
      this.inputValue = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.search-container {
  position: sticky;
  top: var(--header-complete-height);
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 16px 18px;
  background-color: #eceae7;
  z-index: 11;
}

.search-box {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.search-form {
  display: flex;
  flex-grow: 1;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.search-input {
  width: 100%;
  @include p--large;
  margin: 0;
  background: transparent;
  border: 0;
  appearance: none;

  &::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }

  &:active,
  &:focus,
  &:focus-within {
    outline: none;
  }

  @media screen and (min-width: $tablet-landscape) {
  }
}

.icon {
  cursor: pointer;
  margin-right: 10px;
  width: 16px;
}

.close-icon {
  margin-left: 10px;
}

@media screen and (min-width: $tablet-landscape) {
  .search-container {
    padding: 16px 56px;
  }
}
</style>
